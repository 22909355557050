import {BrowserRouter, Route, Routes} from "react-router-dom";
import {QueryParamProvider} from "use-query-params";
import {ReactRouter6Adapter} from "use-query-params/adapters/react-router-6";

import {BackendProvider} from "./component/BackendProvider";
import {NotificationsContainer} from "./component/NotificationsContainer";
import {CustomerPage} from "./page/customer";
import {CustomerListPage} from "./page/customerList";

export function App() {
    return (
        <BrowserRouter>
            <NotificationsContainer/>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <BackendProvider>
                    <Routes>
                        <Route
                            element={<CustomerPage />}
                            path="/customer/:customerId"
                        />
                        <Route
                            element={<CustomerListPage />}
                            index
                        />
                    </Routes>
                </BackendProvider>
            </QueryParamProvider>
        </BrowserRouter>
    );
}

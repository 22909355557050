import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";

import {ICustomerGetResponseInvoice} from "../../remote/CustomerGetResponse";

interface IProps {
    invoices: ICustomerGetResponseInvoice[];
}

export function InvoiceTable(props: IProps): React.ReactElement {
    const {invoices} = props;

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    }));
    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    return (
        <>
            <Typography variant="h4">Invoices</Typography>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Due Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Stripe</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((i) => (
                            <StyledTableRow
                                key={i.invoiceId}
                                sx={{
                                    "&:last-child td, &:last-child th": {border: 0},
                                }}
                            >
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                >
                                    {new Date(i.dueDate).toDateString()}
                                </StyledTableCell>
                                <StyledTableCell>{i.status}</StyledTableCell>
                                <StyledTableCell>
                                    {i.stripeUrl ?
                                        <Link
                                            aria-label="Stripe invoice link"
                                            href={i.stripeUrl}
                                            target="_blank"
                                        >
                                            {i.stripeUrl.split("/").pop()}
                                        </Link>
                                        : "-"}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

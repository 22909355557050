import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import {useParams} from "react-router-dom";

import {useBackend} from "../../component/BackendProvider";
import {useNotification} from "../../hook/useNotification";
import {ICustomerConvertRequest} from "../../remote/CustomerConvertRequest";
import {ICustomerGetResponse} from "../../remote/CustomerGetResponse";
import {ICustomerUpdateRequest} from "../../remote/CustomerUpdateRequest";
import {CustomerDetails} from "./details";

export function CustomerPage(): React.ReactElement {
    const {customerId} = useParams();
    const backend = useBackend();
    const notification = useNotification();

    const [isProcessing, setIsProcessing] = React.useState<boolean>(true);
    const [customer, setCustomer] = React.useState<ICustomerGetResponse>();

    function loadCustomer() {
        setIsProcessing(true);
        backend.invokeApi({
            axiosConfig: {
                method: "GET",
                url: `admin/customer/${customerId}`,
            },
            onFinally: () => {
                setIsProcessing(false);
            },
            onReject: (err) => {
                setCustomer(undefined);
                notification.error("Error retrieving customer", err);
            },
            onResolve: (response: ICustomerGetResponse) => {
                setCustomer(response);
            },
        });
    }

    React.useEffect(() => {
        loadCustomer();
    }, [customerId]);

    const convertCustomer = (customerId: string, request: ICustomerConvertRequest) => {
        setIsProcessing(true);
        backend.invokeApi({
            axiosConfig: {
                data: request,
                method: "POST",
                url: `admin/customer/${customerId}/convert`,
            },
            onFinally: () => {
                setIsProcessing(false);
            },
            onReject: (err) => {
                notification.error("Error converting customer", err);
            },
            onResolve: () => {
                notification.success("Customer converted successfully");
                loadCustomer();
            },
        });
    };

    const updateCustomer = (customerId: string, request: ICustomerUpdateRequest) => {
        setIsProcessing(true);
        backend.invokeApi({
            axiosConfig: {
                data: request,
                method: "POST",
                url: `admin/customer/${customerId}`,
            },
            onFinally: () => {
                setIsProcessing(false);
            },
            onReject: (err) => {
                notification.error("Error updating customer", err);
            },
            onResolve: () => {
                notification.success("Customer updated successfully");
                loadCustomer();
            },
        });
    };

    if (isProcessing || !customer) {
        return (
            <CircularProgress/>
        );
    }

    return (
        <CustomerDetails
            customer={customer}
            onCustomerConvert={convertCustomer}
            onCustomerUpdate={updateCustomer}
        />
    );
}

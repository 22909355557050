import {ReportHandler} from "web-vitals";

export function reportWebVitals(onPerfEntry?: ReportHandler) {
    if (onPerfEntry) {
        import("web-vitals").then(
            ({getCLS, getFID, getFCP, getLCP, getTTFB}) => {
                getCLS(onPerfEntry);
                getFID(onPerfEntry);
                getFCP(onPerfEntry);
                getLCP(onPerfEntry);
                getTTFB(onPerfEntry);
            },
        );
    }
}

import axios, {AxiosInstance, AxiosRequestConfig} from "axios";

interface IInvokeProps<T> {
    axiosConfig: AxiosRequestConfig;
    onResolve?: (result: T) => void;
    onReject?: (error: Error) => void;
    onFinally?: () => void;
}

export class Backend {

    private readonly axios: AxiosInstance;

    public constructor(
        baseUrl: string,
        apiKey: string,
    ) {
        this.axios = axios.create({
            baseURL: baseUrl,
            headers: {
                "Content-Type": "application/json",
                "X-Api-Key": apiKey,
            },
            responseType: "json",
            withCredentials: true,
        });
    }

    public invokeApi<T>(props: IInvokeProps<T>) {
        const {axiosConfig, onFinally, onReject, onResolve} = props;
        this.axios
            .request<T>(axiosConfig)
            .then((result) => {
                onResolve?.(result.data);
            })
            .catch((error) => {
                if (error.isAxiosError) {
                    return onReject?.(error);
                }
                throw error;
            })
            .catch((error) => {
                console.error("Unhandled error", error);
                if (process.env.NODE_ENV !== "test") {
                    throw error;
                }
            })
            .finally(() => {
                onFinally?.();
            });
    }
}

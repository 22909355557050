import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import {useMemo} from "react";

import {
    getComparator,
    ITableHeadCell,
    SortableTableHead,
    TableColumnSortOrder,
} from "../../component/SortableTable";
import {ICustomerListResponseEntry} from "../../remote/CustomerListResponse";

interface IProps {
    customers: ICustomerListResponseEntry[];
    onCustomerClick: (customer: ICustomerListResponseEntry) => void;
}

export function CustomerListTable(props: IProps): React.ReactElement {
    const StyledTableRow = styled(TableRow)(({theme}) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    }));
    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        ":hover": {cursor: "pointer"},
    }));

    const columns: ITableHeadCell<ICustomerListResponseEntry>[] = [{
        id: "name",
        label: "Customer",
    }, {
        id: "domain",
        label: "Domain",
    }, {
        id: "type",
        label: "Type",
    }, {
        id: "status",
        label: "Status",
    }, {
        id: "creationDate",
        label: "Creation Date",
    }, {
        id: "maxTrialActiveStudent",
        label: "Max Active Students",
        numeric: true,
    }, {
        id: "hubSpotUrl",
        label: "HubSpot",
    }];

    const [order, setOrder] = React.useState<TableColumnSortOrder>("asc");
    const [orderBy, setOrderBy] = React.useState<keyof ICustomerListResponseEntry>("name");
    const sortedRows = useMemo<ICustomerListResponseEntry[]>(() => {
        const column = columns.find((c) => c.id === orderBy);
        return props.customers.sort(getComparator(column?.numeric ?? false, order, orderBy));
    }, [order, orderBy, props.customers]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ICustomerListResponseEntry) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="customer table">
                <SortableTableHead
                    headCells={columns}
                    onRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                />
                <TableBody>
                    {sortedRows.map((c) => (
                        <StyledTableRow
                            key={c.customerId}
                            sx={{
                                "&:last-child td, &:last-child th": {border: 0},
                            }}
                        >
                            <StyledTableCell
                                component="th"
                                onClick={() => props.onCustomerClick(c)}
                                scope="row"
                            >
                                {c.name}
                            </StyledTableCell>
                            <StyledTableCell onClick={() => props.onCustomerClick(c)}>{c.domain}</StyledTableCell>
                            <StyledTableCell onClick={() => props.onCustomerClick(c)}>{c.type}</StyledTableCell>
                            <StyledTableCell onClick={() => props.onCustomerClick(c)}>{c.status}</StyledTableCell>
                            <StyledTableCell onClick={() => props.onCustomerClick(c)}>{new Date(c.creationDate).toDateString()}</StyledTableCell>
                            <StyledTableCell onClick={() => props.onCustomerClick(c)}>{c.maxTrialActiveStudent ?? "-"}</StyledTableCell>
                            <TableCell>
                                {c.hubSpotUrl ?
                                    <Link
                                        aria-label="HubSpot company link"
                                        href={c.hubSpotUrl}
                                        target="_blank"
                                    >
                                        {c.hubSpotUrl.split("/").pop()}
                                    </Link>
                                    : "-"}
                            </TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

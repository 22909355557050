import {ThemeProvider} from "@mui/material";
import React from "react";
import {createRoot} from "react-dom/client";

import {App} from "./App";
import {reportWebVitals} from "./reportWebVitals";
import {Theme} from "./theme/theme";

const container = document.getElementById("root");
if (!container) {
    throw Error("Unable to retrieve 'root' element");
}
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={Theme}>
            <App/>
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

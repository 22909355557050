import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";

import {ICustomerConvertRequest} from "../../remote/CustomerConvertRequest";
import {ICustomerGetResponse} from "../../remote/CustomerGetResponse";
import {ICustomerUpdateRequest} from "../../remote/CustomerUpdateRequest";
import {InvoiceTable} from "./invoiceTable";

interface IProps {
    customer: ICustomerGetResponse;
    onCustomerConvert: (customerId: string, request: ICustomerConvertRequest) => void;
    onCustomerUpdate: (customerId: string, request: ICustomerUpdateRequest) => void;
}

export function CustomerDetails(props: IProps): React.ReactElement {
    const {customer, onCustomerConvert, onCustomerUpdate} = props;
    const [isMaxUserModalOpen, setIsMaxUserModalOpen] = React.useState<boolean>(false);
    const maxStudentCountFieldRef = React.useRef<HTMLInputElement>();

    const showActivate = customer.status === "Inactive";
    function activateCustomer() {
        onCustomerUpdate(customer.customerId, {
            status: "Active",
        });
    }

    const showDeactivate = customer.status === "Active";
    function deactivateCustomer() {
        onCustomerUpdate(customer.customerId, {
            status: "Inactive",
        });
    }

    const showUpdateActiveStudents = ["School", "Trial"].includes(customer.type);
    function setMaxActiveStudents() {
        const value = parseInt(maxStudentCountFieldRef.current?.value ?? "0");
        if (value > 0) {
            onCustomerUpdate(customer.customerId, {
                maxTrialActiveStudent: value > 0 ? value : 1,
            });
        }
        setIsMaxUserModalOpen(false);
    }

    const showConvertToFreemium = ["Regular", "School"].includes(customer.type) && customer.status === "Active";
    function convertCustomerToFreemium() {
        onCustomerConvert(customer.customerId, {
            activeStudentLimit: 10,
            licenseCostByoc: 0,
            licenseCostPerCreator: [],
            targetCustomerType: "Trial",
        });
    }

    const showConvertToSchool = customer.type === "Trial" && customer.status === "Active";
    function convertCustomerToSchool() {
        onCustomerConvert(customer.customerId, {
            licenseCostByoc: 0,
            licenseCostPerCreator: [{
                cost: 0,
                creatorId: "CodaKid",
            }],
            skipInvoiceGeneration: true,
            targetCustomerType: "School",
        });
    }

    return (
        <>
            <Dialog
                onClose={() => setIsMaxUserModalOpen(false)}
                open={isMaxUserModalOpen}
            >
                <DialogTitle>Student Limit</DialogTitle>
                <DialogContent>
                    <DialogContentText paddingBottom="1em">
                        Maximum active student accounts allowed for customer:
                    </DialogContentText>
                    <TextField
                        defaultValue={customer.maxTrialActiveStudent ?? 1}
                        label="Number"
                        inputRef={maxStudentCountFieldRef}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsMaxUserModalOpen(false)}>Cancel</Button>
                    <Button onClick={setMaxActiveStudents}>Update</Button>
                </DialogActions>
            </Dialog>


            <Box>
                <Typography variant="h4">Customer</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Customer</TableCell>
                                <TableCell>Domain</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Creation Date</TableCell>
                                <TableCell>Max Active Students</TableCell>
                                <TableCell>HubSpot</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                key={customer.customerId}
                                sx={{
                                    "&:last-child td, &:last-child th": {border: 0},
                                }}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                >
                                    {customer.name}
                                </TableCell>
                                <TableCell>{customer.domain}</TableCell>
                                <TableCell>{customer.type}</TableCell>
                                <TableCell>{customer.status}</TableCell>
                                <TableCell>{new Date(customer.creationDate).toDateString()}</TableCell>
                                <TableCell>{customer.maxTrialActiveStudent ?? "-"}</TableCell>
                                <TableCell>
                                    {customer.hubSpotUrl ?
                                        <a href={customer.hubSpotUrl}>{customer.hubSpotUrl.split("/").pop()}</a> : "-"}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Box sx={{
                paddingTop: "2em",
            }}>
                <Grid container flexDirection="row">
                    {showActivate && (
                        <Button
                            onClick={activateCustomer}
                            variant="contained"
                        >
                            Reactivate
                        </Button>
                    )}
                    {showDeactivate && (
                        <Button
                            onClick={deactivateCustomer}
                            variant="contained"
                        >
                            Deactivate
                        </Button>
                    )}
                    {showUpdateActiveStudents && (
                        <Button
                            onClick={() => setIsMaxUserModalOpen(true)}
                            variant="contained"
                        >
                            Set Student Limit
                        </Button>
                    )}
                    {showConvertToFreemium && (
                        <Button
                            onClick={convertCustomerToFreemium}
                            variant="contained"
                        >
                            Convert to Freemium
                        </Button>
                    )}
                    {showConvertToSchool && (
                        <Button
                            onClick={convertCustomerToSchool}
                            variant="contained"
                        >
                            Convert to School
                        </Button>
                    )}
                </Grid>
            </Box>

            <Box sx={{
                paddingTop: "2em",
            }}>
                <InvoiceTable invoices={customer.invoices}/>
            </Box>
        </>
    );
}

import {getEnv} from "./getEnv";

export function getSetupApiDomain() {
    switch (getEnv()) {
        case "beta":
            return "https://setup.api.codenow-beta.live/v1/";
        default:
            return "https://setup.api.codenow.live/v1/";
    }
}

import * as React from "react";
import {StringParam, useQueryParam} from "use-query-params";

import {getSetupApiDomain} from "../config/getApiDomain";
import {Backend} from "../remote/Backend";

interface IProps {
    children: React.ReactNode;
}

const BackendContext = React.createContext<Backend>(new Backend("", ""));

/** Retrieve apiKey from query parameter when the application is initialized. */
export function BackendProvider(props: IProps): JSX.Element {
    const [apiKeyQuery] = useQueryParam("apiKey", StringParam);
    const backend = React.useMemo(() => new Backend(getSetupApiDomain(), apiKeyQuery ?? ""), []);

    return <BackendContext.Provider value={backend}>
        {props.children}
    </BackendContext.Provider>;
}

export function useBackend(): Backend {
    return React.useContext(BackendContext);
}

import "react-toastify/dist/ReactToastify.css";

import * as React from "react";
import {useLocation} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";

export function NotificationsContainer(): React.ReactElement {
    const {pathname} = useLocation();

    React.useEffect(() => {
        toast.dismiss();
    }, [pathname]);

    return (
        <ToastContainer
            autoClose={false}
            closeOnClick={false}
            newestOnTop={true}
            pauseOnHover={true}
            position={"top-right"}
        />
    );
}

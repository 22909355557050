import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import {useNavigate} from "react-router-dom";

import {useBackend} from "../../component/BackendProvider";
import {useNotification} from "../../hook/useNotification";
import {ICustomerListResponse, ICustomerListResponseEntry} from "../../remote/CustomerListResponse";
import {CustomerListTable} from "./table";

export function CustomerListPage(): React.ReactElement {
    const backend = useBackend();
    const notification = useNotification();
    const navigate = useNavigate();

    const [customers, setCustomers] = React.useState<ICustomerListResponseEntry[]>();

    React.useEffect(() => {
        backend.invokeApi({
            axiosConfig: {
                method: "GET",
                url: "admin/customer",
            },
            onReject: (err) => {
                setCustomers(undefined);
                notification.error("Error retrieving customers", err);
            },
            onResolve: (response: ICustomerListResponse) => {
                setCustomers(response.customers);
            },
        });
    }, []);

    const onCustomerClick = (customer: ICustomerListResponseEntry) => {
        navigate(`/customer/${customer.customerId}`);
    };

    if (!customers) {
        return (
            <CircularProgress/>
        );
    }

    return (
        <CustomerListTable
            customers={customers}
            onCustomerClick={onCustomerClick}
        />
    );
}

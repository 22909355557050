import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";
import * as React from "react";

export type TableColumnSortOrder = "asc" | "desc";

export interface ITableProps<T> {
    headCells: ITableHeadCell<T>[];
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    order: TableColumnSortOrder;
    orderBy: string;
}

export interface ITableHeadCell<T> {
    id: keyof T;
    label: string;
    numeric?: boolean;
}

export function getComparator<T>(
    numeric: boolean,
    order: TableColumnSortOrder,
    orderBy: keyof T,
): (a: T, b: T) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, numeric, orderBy)
        : (a, b) => -descendingComparator(a, b, numeric, orderBy);
}

export function descendingComparator<T>(a: T, b: T, numeric: boolean, orderBy: keyof T) {
    if (numeric) {
        return parseFloat(b[orderBy]?.toString() ?? "0") - parseFloat(a[orderBy]?.toString() ?? "0");
    }

    const aString = (a[orderBy]?.toString() ?? "").toLowerCase();
    const bString = (b[orderBy]?.toString() ?? "").toLowerCase();
    if (bString < aString) {
        return -1;
    }
    if (bString > aString) {
        return 1;
    }
    return 0;
}

export function SortableTableHead<T>(props: ITableProps<T>) {
    const {headCells, onRequestSort, order, orderBy} = props;
    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id?.toString()}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
